import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { BlockContent } from '../components/BlockContent'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import { imageUrlFor, buildImageObj } from '../helpers/imageUrl.js'
import { SplitImage } from '../components/SplitImage'
import { Aircraft } from '../definitions/aircraft'
import { AircraftTechData } from '../components/AircraftTechData/AircraftTechData'

const PageWrapper = styled.div``

interface Data {
  data: {
    aircraft: Aircraft
  }
}

const AircraftPage = ({ data }: Data): JSX.Element => {
  const { aircraft } = data

  const mainImage =
    aircraft.image &&
    imageUrlFor(buildImageObj(aircraft.image))
      .width(800)
      .height(Math.floor((4 / 3) * 800))
      .fit('crop')
      .auto('format')
      .url()

  return (
    <Layout>
      <SEO
        title={aircraft.seoData?.title || 'Aircraft'}
        description={aircraft.seoData?._rawDescription}
        location={aircraft.seoData?.location}
      />

      <PageWrapper>
        {aircraft.image && (
          <SplitImage src={mainImage} alt={aircraft.image.alt}>
            <h1>{aircraft.tailNumber}</h1>
            <h2>{`${aircraft.year} ${aircraft.make} ${aircraft.model}`}</h2>

            <BlockContent body={aircraft._rawDescription} />

            <AircraftTechData technicalData={aircraft.technicalData} />
          </SplitImage>
        )}
      </PageWrapper>
    </Layout>
  )
}

export default AircraftPage

export const query = graphql`
  query AircraftPageQuery($id: String!) {
    aircraft: sanityAircraft(id: { eq: $id }) {
      _rawDescription
      make
      model
      tailNumber
      technicalData {
        usefulLoad
        takeoffRoll
        speed
        range
        passengers
        ceiling
      }
      year
      image {
        alt
        ...SanityImage
      }
      seoData {
        _rawDescription
        location
        title
      }
    }
  }
`
