import React, { useState } from 'react'
import styled from 'styled-components'
import { TechnicalData } from '../../definitions/aircraft'
import { fontSizes } from '../GlobalStyles/vars/fontSizes'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { colors } from '../GlobalStyles/vars/colors'

const Container = styled.div`
  width: fit-content;
  margin: 20px auto;
  font-size: ${fontSizes.sm};

  h2 {
    font-size: ${fontSizes.md};
    margin-bottom: -5px;
    text-align: center;
  }

  .hide {
    height: 0;
  }

  .show {
    height: 220.5px;
  }
`

const Wrapper = styled.ul`
  overflow: hidden;
  transition: height 0.2s ease-in-out;
`

const Button = styled.button`
  margin: 10px auto;
  font: inherit;
  border: 2px solid;
  border-radius: 50%;
  height: 38px;
  width: 38px;
  background-color: inherit;
  border-color: ${colors.lightPrimary};
  color: ${colors.lightPrimary};
  display: flex;
  justify-content: space-around;
  align-items: center;
`

interface Props {
  technicalData: TechnicalData
}

export const AircraftTechData = ({ technicalData }: Props): JSX.Element => {
  const [state, setState] = useState(false)

  return (
    <Container>
      <h2>Technical Data</h2>
      <Wrapper className={state ? 'show' : 'hide'}>
        <li>Service Ceiling: {technicalData.ceiling}ft</li>
        <li>Seats: {technicalData.passengers}</li>
        <li>Range: {technicalData.range}nm</li>
        <li>Cruising Speed: {technicalData.speed}kts</li>
        <li>Takeoff Roll: {technicalData.takeoffRoll}ft</li>
        <li>Usefull Load: {technicalData.usefulLoad}lbs</li>
      </Wrapper>
      <Button onClick={() => setState(!state)}>{state ? <IoIosArrowUp /> : <IoIosArrowDown />}</Button>
    </Container>
  )
}
